
.CaptureButtonStyle {
  border-radius: 100%;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: rgb(192, 76, 76);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  text-align: center;
  height: 50px;
  width: 50px;
}
.CaptureButtonContainer {
  position: relative;
  width: 100%;
  max-width: 500px;
};